<form [formGroup]="formGroup" class="shipment-method-selector col">
  <div class="title">Shipment Method</div>
  <div class="shipment-type" [ngClass]="{ 'radio-hidden': radioHidden() }">
    <mat-radio-group
      *ngIf="!radioHidden()"
      [disabled]="viewOnly() || disabled()"
      aria-label="Select an option"
      [value]="shipmentMethod.value"
      (change)="changeLoadType($event)">
      <mat-radio-button *ngFor="let loadType of loadTypes()" [value]="loadType.type">{{
        loadType.type
      }}</mat-radio-button>
    </mat-radio-group>
    <div class="air-freight" *ngIf="radioHidden()">Air Freight</div>
  </div>
  <div formArrayName="lcl" *ngIf="shipmentMethod.value === 'LCL'">
    <div *ngFor="let item of lclArray?.controls; let i = index" [formGroupName]="i">
      <div class="lcl">
        <mat-form-field appearance="outline">
          <mat-label>Volume Weight</mat-label>
          <input matInput type="number" formControlName="volumeWeight" placeholder="CBM" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>No of Truck Loads</mat-label>
          <input matInput type="number" formControlName="noOfLoads" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div formArrayName="fcl" *ngIf="shipmentMethod.value === 'FCL'">
    <div *ngFor="let item of fclArray?.controls; let i = index" [formGroupName]="i">
      <div class="row-1">
        <div class="fcl">
          <mat-form-field appearance="outline">
            <mat-label>Container Type</mat-label>
            <mat-select formControlName="containerType">
              <mat-option *ngFor="let containerType of containerTypes()" [value]="containerType.id">{{
                containerType.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>No of Containers</mat-label>
            <input matInput type="number" formControlName="noOfLoads" />
          </mat-form-field>
          <button
            mat-mini-fab
            class="delete-btn"
            [ngClass]="{ disabled: viewOnly() || fclArray.length === 1 }"
            [disabled]="viewOnly() || fclArray.length === 1"
            (click)="deleteItem(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="footer flex items-center" *ngIf="shipmentMethod.value === 'FCL'">
    <button
      mat-mini-fab
      class="add-btn"
      [ngClass]="{ disabled: viewOnly() }"
      [disabled]="viewOnly()"
      (click)="addFCL()">
      <mat-icon>add</mat-icon>
    </button>
    <span [ngClass]="{ disabled: viewOnly() }">Add Container Type</span>
    <div class="horizontal-line"></div>
  </div>
</form>
