import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { GraphqlClientService } from '@core/services/graphql-client.service';
import { FieldType } from '@ngx-formly/core';
import { Store } from '@ngxs/store';
import { GET_CONTAINER_TYPES_QUERY } from '@shared/gql-shared-queries';
import { catchError, Subject, take, takeUntil, tap } from 'rxjs';
import { Order } from '../../models/order.model';
import { ShipmentMethodDetail } from '../../models/shipment-method-details';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-order-shipment-method-selector',
  templateUrl: './order-shipment-method-selector.component.html',
  styleUrl: './order-shipment-method-selector.component.scss',
})
export class OrderShipmentMethodSelectorComponent extends FieldType implements OnInit, OnDestroy {
  store$ = inject(Store);
  graphqlClient = inject(GraphqlClientService);
  fb = inject(FormBuilder);

  selectedOrder$ = this.store$.select(WizardState.getSelectedOrder);

  destroy$ = new Subject<void>();
  containerTypes = signal<{ id: string; name: string }[]>([]);
  formGroup: FormGroup;
  radioHidden = signal(false);
  viewOnly = signal(false);
  disabled = signal(false);
  loadTypes = signal([{ type: 'FCL' }, { type: 'LCL' }]);

  ngOnInit(): void {
    this.viewOnly.set(this.props['viewOnly']);
    this.generateDefaultForm();
    this.fetchContainerTypes();
    this.listenToFormValueChanges();
    this.shipmentMethodChangeListener();
  }

  private generateDefaultForm(): void {
    this.formGroup = this.fb.group({
      shipmentMethod: ['FCL'],
      lcl: this.fb.array([]),
      fcl: this.fb.array([this.createFclGroup()]),
    });
  }

  private listenToFormValueChanges(): void {
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.getLoadDetails().setValue(value);
    });
  }

  private fetchContainerTypes(): void {
    this.graphqlClient
      .query<{ containerTypes: { _id: string; name: string }[] }, undefined>(GET_CONTAINER_TYPES_QUERY)
      .pipe(
        take(1),
        tap(response => {
          const containerTypes = response?.containerTypes;
          this.containerTypes.set(
            containerTypes.map(containerType => ({
              id: containerType._id,
              name: containerType.name,
            }))
          );
        }),
        catchError(async err => console.error('Error fetching container types', err))
      )
      .subscribe();
  }

  get lclArray() {
    return this.formGroup.get('lcl') as FormArray;
  }

  get fclArray() {
    return this.formGroup.get('fcl') as FormArray;
  }

  get shipmentMethod() {
    return this.formGroup.get('shipmentMethod') as FormControl;
  }

  getLoadDetails() {
    return this.form.controls['loadDetails'] as FormControl;
  }

  changeLoadType(event: MatRadioChange) {
    this.formGroup.get('shipmentMethod')?.setValue(event.value);
    if (event.value === 'FCL') {
      this.clearAllArray();
      this.addFCL();
    } else {
      this.clearAllArray();
      this.addLCL();
    }
  }

  private clearAllArray() {
    this.fclArray.clear();
    this.lclArray.clear();
  }

  deleteItem(index: number) {
    this.fclArray.removeAt(index);
  }

  createFclGroup(loadDetails?: ShipmentMethodDetail): FormGroup {
    return this.fb.group({
      containerType: [{ value: loadDetails?.containerType ?? '', disabled: this.viewOnly() }, Validators.required],
      noOfLoads: [
        { value: loadDetails?.noOfLoads ?? 0, disabled: this.viewOnly() },
        [Validators.required, Validators.min(1), Validators.pattern(/^[0-9]+$/)],
      ],
      volumeWeight: 0,
    });
  }

  createLclGroup(loadDetails?: ShipmentMethodDetail): FormGroup {
    return this.fb.group({
      noOfLoads: [
        { value: loadDetails?.noOfLoads ?? 0, disabled: this.viewOnly() },
        [Validators.required, Validators.min(1), Validators.pattern(/^[0-9]+$/)],
      ],
      volumeWeight: [
        { value: loadDetails?.volumeWeight ?? 0, disabled: this.viewOnly() },
        [Validators.required, Validators.min(1), Validators.pattern(/^[0-9]+$/)],
      ],
      containerType: '',
    });
  }

  addLCL(loadDetails?: ShipmentMethodDetail) {
    const itemForm = this.createLclGroup(loadDetails);
    this.lclArray.push(itemForm);
  }

  addFCL(loadDetails?: ShipmentMethodDetail) {
    const itemForm = this.createFclGroup(loadDetails);
    this.fclArray.push(itemForm);
  }

  private shipmentMethodChangeListener(): void {
    this.field.props['onClick'] = (
      selectedMode: { label: string; value: string; transhipmentAllowed: boolean },
      order: Order
    ) => {
      const selectedShipmentMode = selectedMode.value;
      this.clearAllArray();

      // Update UI state based on the selected shipment mode.
      this.updateUIBasedOnSelectedMode(selectedShipmentMode);

      // If an order is provided and its preferred shipment mode matches the selected mode,
      // populate the load details accordingly; otherwise, use the default behavior.
      if (order && order.shipmentDetails.preferredShipmentMode === selectedShipmentMode) {
        this.processOrderLoadDetails(order);
      } else {
        this.handleDefaultLoadDetails(selectedShipmentMode);
      }
    };
  }

  /**
   * Updates the UI state based on the selected shipment mode.
   * If "AIR" is selected, hides the radio and sets the shipment method to "LCL".
   * Otherwise, shows the radio and sets the shipment method to "FCL".
   */
  private updateUIBasedOnSelectedMode(selectedShipmentMode: string): void {
    if (selectedShipmentMode === 'AIR') {
      this.radioHidden.set(true);
      this.formGroup.get('shipmentMethod')?.setValue('LCL');
    } else {
      this.radioHidden.set(false);
      this.formGroup.get('shipmentMethod')?.setValue('FCL');
    }
  }

  /**
   * Processes the order's load details when the order's preferred shipment mode
   * matches the selected shipment mode.
   * It sets the shipment method based on order.shipmentMethod and
   * adds the corresponding load groups.
   */
  private processOrderLoadDetails(order: Order): void {
    if (order.shipmentMethod === 'FCL') {
      this.formGroup.get('shipmentMethod')?.setValue('FCL');
      order.loadDetails.forEach(loadDetail => {
        this.addFCL(loadDetail);
      });
    } else {
      this.formGroup.get('shipmentMethod')?.setValue('LCL');
      order.loadDetails.forEach(loadDetail => {
        this.addLCL(loadDetail);
      });
    }
  }

  /**
   * Handles the default case where the order is either not provided or its
   * preferred shipment mode does not match the selected mode.
   * If the selected mode is "AIR", the LCL load group is added.
   * Otherwise, the load group added depends on the current shipment method value.
   */
  private handleDefaultLoadDetails(selectedShipmentMode: string): void {
    if (selectedShipmentMode === 'AIR') {
      this.addLCL();
    } else {
      if (this.formGroup.get('shipmentMethod')?.value === 'FCL') {
        this.addFCL();
      } else {
        this.addLCL();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
